.fm-top-bar {
  background: #fff;
}

.fm-top-bar-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction:  row;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0;
  height: 90px;
}

.fm-top-bar-brand {
  margin-right: 10px;
}

.fm-top-bar-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.fm-top-bar-wordmark {
  color: #FF206E;
  padding: 8px 10px 0px 10px;
  display: inline-block;
  cursor: pointer;
  transition: ease all 0.2s;
}

.fm-top-bar-wordmark-img {
  height: 24px;
}

.fm-top-bar-link {
  color: $secondary;
  display: inline-block;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 20px;
  padding: 11px 10px 9px 10px;
  margin-left: 30px;
  cursor: pointer;
  transition: ease all 0.2s;
}

.fm-top-bar-link-special {
  color: $primary;
  margin-right: 5px;
  font-size: 14px;
  font-weight: 700;
}

.fm-top-bar-link:hover, .fm-top-bar-wordmark:hover {
  transform: translate(0px, -3px);
  -webkit-transform: translate(0px, -3px);
}

.fm-top-bar-mobile {
  display: none;
  background-image: url('https://storage.googleapis.com/flamingo-static/images/facade/menu.png');
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  height: 25px;
  width: 25px;
  margin-right: 15px;
}

.fm-top-bar-open .fm-top-bar-mobile {
  background-image: url('https://storage.googleapis.com/flamingo-static/images/facade/close.png');
}

/* LEGAL BULLSHIT */
.fm-top-bar-wordmark-tm {
  font-size: 4px;
  vertical-align: top;
  font-family: sans-serif;
  font-weight: bold;
  display: inline-block;
  line-height: 10px;
  padding-left: 1px;
}
/* REMOVE ONE DAY */

@media(max-width: $breakpoint-md) {
  .fm-top-bar-link {
    margin-left: 5px;
  }
}

@media(max-width: $breakpoint-sm) {
  .fm-top-bar.fm-top-bar-open {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background: #fff;
  }
  .fm-top-bar-left {
    flex: 1;
  }
  .fm-top-bar-mobile {
    display: block;
  }
  .fm-top-bar-links {
    display: none;
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 9999;
    height: auto;
  }
  .fm-top-bar-link {
    margin: 30px 20px;
    font-size: 20px;
    font-weight: 600;
    display: block;
  }
  .fm-top-bar-link-special {
    display: block;
    margin-top: 40px;
  }
  .fm-top-bar-open .fm-top-bar-links {
    display: block;
  }
}
