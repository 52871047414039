.fm-city-safety {
  margin-bottom: 10px;
}

.fm-city-safety-container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 50px;
}

.fm-city-safety-loading {
  padding: 50px 0;
}

.fm-city-safety-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  padding: 10px 0 20px 0;
}

.fm-city-safety-text {
  line-height: 25px;
  margin-bottom: 25px;
}

/* Items */

.fm-city-safety-item-image {
  width: 50px;
  height: 50px;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.fm-city-safety-items {
  display: flex;
  flex-wrap: wrap;
}

.fm-city-safety-item {
  width: 20%;
  margin: 30px 5% 40px 0;
}

.fm-city-safety-item-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  margin: 20px 0 10px 0;
}

.fm-city-safety-item-message {
  font-size: 15px;
  line-height: 22px;
}

@media(max-width: $breakpoint-md) {
  .fm-city-safety-container {
    padding: 0 40px;
  }
  .fm-city-safety-item {
    width: 29%;
    margin: 50px 4% 20px 0;
  }
}

@media(max-width: $breakpoint-sm) {
  .fm-city-safety-item {
    width: 48%;
    margin: 50px 4% 20px 0;
  }
  .fm-city-safety-item:nth-child(2n) {
    margin: 50px 0% 20px 0;
  }
}

/* Print Styling */

// .fm-how-to-safety-items {
//   gap: 25px 30px !important;
//   width: 720px !important;
// }
//
// .fm-how-to-safety-item {
//   width: calc((100% - 60px) / 3) !important;
//   margin: 0 !important;
// }

/* AR Styling */

// .fm-how-to-safety-item-title {
//   direction: rtl;
// }
//
// .fm-how-to-safety-item-message {
//   direction: rtl;
// }
//
// .fm-how-to-safety-item-image {
//     margin-left: 169px;
// }
