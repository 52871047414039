.fm-ai-error {
  background: #ff206e;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.fm-ai-error-text {
  color: #fff;
  text-align: center;
  padding: 20px;
  line-height: 20px;
  font-weight: 600;
  font-size: 20px;
}

.fm-ai-error-instruction {
  color: #fff;
  text-align: center;
  padding: 20px;
  line-height: 20px;
}

.fm-ai-loading {
  background: #ff206e;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fm-ai-loader {
  width: 48px;
  height: 48px;
  display: block;
  margin: 20px auto;
  box-sizing: border-box;
  position: relative;
}

.fm-ai-loader::after {
  content: '';
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  left: 0;
  bottom: 0;
  position: absolute;
  border-radius: 50% 50% 0;
  border: 15px solid #FFF;
  transform: rotate(45deg) translate(0, 0);
  box-sizing: border-box;
  animation: animMarker 0.4s ease-in-out infinite alternate;
}

.fm-ai-loader::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 150%;
  width: 24px;
  height: 4px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  animation: animShadow 0.4s ease-in-out infinite alternate;
}

@keyframes animMarker {
  0% {
    transform: rotate(45deg) translate(5px, 5px);
  }
  100% {
    transform: rotate(45deg) translate(-5px, -5px);
  }
}

@keyframes animShadow {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

.fm-ai-loaded {
  animation: animSlide 0.5s ease-in-out forwards;
}

@keyframes animSlide {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(10);
    opacity: 0;
  }
}

.fm-ai-content {
  width: 100%;
  padding: 10px 15px 30px 15px;
}

.fm-ai-content h1 {
  font-size: 32px;
  line-height: 45px;
  margin: 5px 0 15px 0;
}

.fm-ai-content h3 {
  border-left: 4px solid #ff206e;
  padding: 0 0 0 6px;
  font-size: 14px;
  font-weight: 700;
  color: #999;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin: 10px 0 5px 0;
}

.fm-ai-content p {
  font-size: 15px;
  line-height: 26px;
  margin: 20px 0;
}

.fm-ai-content img {
  width: 100%;
  border-radius: 5px;
}

.fm-ai-content blockquote {
  background: #ff206e;
  padding: 15px;
  border-radius: 5px;
  margin: 20px 0;
}

.fm-ai-content blockquote p {
  color: #fff;
  font-weight: 600;
  line-height: 25px;
  margin: 0;
}

.fm-ai-content mark {
  background: #ff206e;
  color: #fff;
  font-weight: 600;
  padding: 2px 4px;
  border-radius: 3px;
}

.fm-ai-content a {
  color: #ff206e;
  font-weight: 600;
}
