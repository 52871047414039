.fm-about-benefits {
  margin: 25px;
}

.fm-about-benefits-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  padding: 20px 0 40px 0;
}

.fm-about-benefits-items {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  max-width: 1150px;
  width: 100%;
  margin: 0px auto;
  padding: 20px 25px;
}

.fm-about-benefits-item-description {
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.2px;
}

.fm-about-benefits-item {
  flex: 1;
  padding-right: 100px;

  &:last-child {
    padding-right: 0;
  }
}

.fm-about-benefits-item-image {
  width: 60px;
  height: 60px;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fm-about-benefits-item-title {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  margin: 15px 0;
  letter-spacing: 0.2px;
}

@media(max-width: $breakpoint-lg) {
  .fm-about-benefits-items {
    flex-direction: column;
    align-items: unset;
    padding: 20px;
  }
  .fm-about-benefits-item {
    flex: 1 1;
    padding-right: 0;
    margin-bottom: 50px;
  }
}

@media(max-width: $breakpoint-md) {
  .fm-about-benefits {
    margin: 20px;
  }
  .fm-about-benefits-item-title {
    font-size: 18px;
    background-image: none;
  }
  .fm-about-benefits-container {
    padding: 40px;
  }
}

@media(max-width: $breakpoint-sm) {
  .fm-about-benefits {
    margin: 15px;
  }
}
