@import './components/splash-images/splash-images.scss';
@import './components/locations/locations.scss';
@import './components/benefits/benefits.scss';
@import './components/how-to-ride/how-to-ride.scss';
@import './components/workplace/workplace.scss';

.fm-home-facebook {
  max-width: 500px;
  margin: 65px auto 50px auto;
  text-align: center;
  padding: 0 20px;
}

.fm-home-facebook-title {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 25px;
}

.fm-home-facebook-button {
  -webkit-appearance: none;
  border: 0;
  font-size: 20px;
  font-weight: 600;
  padding: 13px 1px;
  line-height: 30px;
  transition: ease all 0.1s;
  display: inline-block;
  width: 240px;
  margin: 30px 15px;
  border-radius: 28px;
  letter-spacing: 0.2px;
  text-align: center;
  background: $primary;
  color: #fff;
}

.fm-home-facebook-button:hover {
  background: #e2165d;
  color: #ddd;
  cursor: pointer;
}
