.fm-wlg-parking {
  margin: 25px;
}

.fm-wlg-parking {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 20px 50px 60px 50px;
}

.fm-wlg-parking-items {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  padding: 25px 0;
}

.fm-wlg-parking-item-description {
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.2px;
}

.fm-wlg-parking-item {
  flex: 1;
  padding-right: 100px;

  &:last-child {
    padding-right: 0;
  }
}

.fm-wlg-parking-item-image {
  width: 60px;
  height: 60px;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fm-wlg-parking-item-title {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  margin: 15px 0;
  letter-spacing: 0.2px;
}

.fm-wlg-parking-description {
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.2px;
  padding: 30px 0;
}

.fm-wlg-parking-map {
  height: 500px;
  margin: 40px 0 10px 0;
}

@media(max-width: $breakpoint-lg) {
  .fm-wlg-parking {
    margin: 20px;
    padding: 0 25px 25px 25px;
    width: auto;
  }
  .fm-wlg-parking-item {
    flex: 1 1;
    padding-right: 50px;
  }
}

@media(max-width: $breakpoint-md) {
  .fm-wlg-parking-item-title {
    font-size: 18px;
    background-image: none;
  }
  .fm-wlg-parking-container {
    padding: 40px;
  }
}

@media(max-width: $breakpoint-sm) {
  .fm-wlg-parking-items {
    flex-direction: column;
    align-items: unset;
  }
  .fm-wlg-parking {
    margin: 15px;
    padding: 0 10px 25px 10px;
  }
  .fm-wlg-parking-item {
    padding-right: 0;
    margin-bottom: 40px;
  }
}
