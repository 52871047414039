.fm-careers-content {
  padding: 50px 0;
  margin: 0 auto;
}

.fm-career {
  padding: 20px 0 80px 0;
  display: flex;
}

.fm-career-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  padding-right: 50px;
  min-width: 250px;
  flex: 0.7;
  margin: 8px 0;
  box-sizing: border-box;
}

.fm-career-container {
  flex: 1;
}

.fm-career-details {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  p {
    font-size: 16px;
    line-height: 25px;
    display: block;
    margin: 10px 0 10px 0;
  }

  li {
    font-size: 16px;
    line-height: 25px;
    margin: 5px 0 5px 20px;
  }
}

.fm-career-apply {
  line-height: 25px;
  font-size: 15px;
  padding: 15px 20px;
  background: #0c0f0a;
  color: #fff;
  font-weight: 600;
  letter-spacing: .2px;
  margin: 15px 0;
  cursor: pointer;
  display: inline-block;
}

.fm-careers-loading {
  padding: 200px 0;
}

.fm-careers-summary-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  margin-bottom: 15px;
}

.fm-career-link {
  display: block;
  font-size: 16px;
  line-height: 25px;
}

.fm-career-list-item {
  margin: 10px 0 10px 20px;
}

.fm-careers-summary {
  margin-bottom: 30px;
}

@media(max-width: $breakpoint-lg) {
  .fm-careers-content {
    padding: 50px 20px;
  }
}

@media(max-width: $breakpoint-md) {
  .fm-career {
    flex-direction: column;
  }
  .fm-career-title {
    flex: 1;
    margin-bottom: 15px;
    font-size: 25px;
    line-height: 35px;
  }
  .fm-careers-summary-title {
    font-size: 25px;
    line-height: 35px;
  }
}
