.fm-city-how-to {
  margin: 50px 0;
}

.fm-city-how-to-container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 50px;
}

.fm-city-how-to-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  padding: 10px 0 20px 0;
  margin-bottom: 20px;
}

.fm-city-how-to-boxes {
  display: flex;
  align-items: center;
}

.fm-city-how-to-box {
  flex: 1;
  background-position: 50%;
  background-size: cover;
  height: 450px;
}

.fm-city-how-to-box-gradient {
  width: 100%;
  height: 100%;
  display: flex;
  background: none;
  background: linear-gradient(45deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%);
  align-items: flex-end;
}

.fm-city-how-to-box-scooter {
  background-image: url('https://storage.googleapis.com/flamingo-static/images/facade/how-to-scooter.jpg');
}

.fm-city-how-to-box-bike {
  background-image: url('https://storage.googleapis.com/flamingo-static/images/facade/how-to-bike.jpg');
  margin-left: 25px;
}

.fm-city-how-to-box-title {
  font-weight: 600;
  font-size: 40px;
  width: 200px;
  margin-bottom: 80px;
  line-height: 50px;
  letter-spacing: 0.2px;
  color: #fff;
  margin: 0 0 50px 50px;
}

@media(max-width: $breakpoint-xl) {
  .fm-city-how-to-box {
    height: 400px;
  }
  .fm-city-how-to-box-title {
    font-size: 35px;
    line-height: 45px;
  }
}

@media(max-width: $breakpoint-lg) {
  .fm-city-how-to-box-bike {
    margin-left: 20px;
  }
}

@media(max-width: $breakpoint-md) {
  .fm-city-how-to-container {
    padding: 0 40px;
  }
  .fm-city-how-to-box {
    height: 300px;
  }
  .fm-city-how-to-box-title {
    margin-left: 40px;
    margin-bottom: 30px;
  }
}

@media(max-width: $breakpoint-sm) {
  .fm-city-how-to-box-bike {
    margin-left: 0;
  }
  .fm-city-how-to-boxes {
    display: block;
  }
  .fm-city-how-to-box {
    width: 100%;
    margin-bottom: 15px;
    display: block;
  }
  .fm-city-how-to-box-title {
    margin-left: 40px;
    margin-bottom: 30px;
  }
}
