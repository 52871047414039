.fm-about-follow {
  margin: 80px 25px 120px 25px;
}

.fm-about-follow-container {
  max-width: 1150px;
  width: 100%;
  margin: 0 auto;
  padding: 0 25px 20px 25px;
}

.fm-about-follow-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  padding: 20px 0 40px 0;
}

.fm-about-follow-description {
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.2px;
}

.fm-about-socials {
  margin: 40px 0 20px 0;
  font-size: 0;
  line-height: 0;
}

.fm-about-social {
  margin: 0 20px 20px 0;
  background-position: 50%;
  background-size: contain;
  width: 36px;
  height: 36px;
  display: inline-block;
  font-size: 0;
  line-height: 0;
}

.fm-about-social-facebook {
  background-image: url('https://storage.googleapis.com/flamingo-static/images/facade/about-facebook.png');
}

.fm-about-social-twitter {
  background-image: url('https://storage.googleapis.com/flamingo-static/images/facade/about-twitter.png');
}

.fm-about-social-tiktok {
  background-image: url('https://storage.googleapis.com/flamingo-static/images/facade/about-tiktok.png');
}

.fm-about-social-instagram {
  background-image: url('https://storage.googleapis.com/flamingo-static/images/facade/about-instagram.png');
}

.fm-about-social-linkedin {
  background-image: url('https://storage.googleapis.com/flamingo-static/images/facade/about-linkedin.png');
}

.fm-about-button {
  line-height: 25px;
  font-size: 15px;
  padding: 15px 20px;
  background: #0c0f0a;
  color: #fff;
  font-weight: 600;
  letter-spacing: .2px;
  margin: 15px 0;
  cursor: pointer;
  display: inline-block;
}

@media(max-width: $breakpoint-lg) {
  .fm-about-follow {
    margin: 50px 20px 120px 20px;
  }
}

@media(max-width: $breakpoint-md) {
  .fm-about-follow-container {
    padding: 0 20px 0 20px;
  }
  .fm-about-blurb-text {
    font-size: 18px;
    padding: 20px 0 0 0;
  }
}

@media(max-width: $breakpoint-sm) {
  .fm-about-follow {
    margin: 30px 15px 80px 15px;
  }
}
