.fm-ride-content {
  max-width: 450px;
  padding: 50px 0;
}

.fm-ride .fm-text {
  text-align: center;
}

.fm-ride-app {
  display: block;
  margin: 50px auto 0 auto;
  text-align: center;
}

@media(max-width: $breakpoint-lg) {
  .fm-ride-content {
    padding: 50px 20px;
  }
}
