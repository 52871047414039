.fm-city-service-area {
}

.fm-city-service-area-map {
  background: #e2e2e2;
  margin: 25px;
  height: 500px;
}

.fm-city-service-area-container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 50px;
}

.fm-city-service-area-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  padding: 10px 0 20px 0;
}

.fm-city-service-area-text {
  line-height: 25px;
}

@media(max-width: $breakpoint-md) {
  .fm-city-service-area-map {
    margin: 15px;
  }
  .fm-city-service-area-container {
    padding: 0 40px;
  }
}
