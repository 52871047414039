.fm-home-splash-images {
  position: relative;
  background: #fff;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px 50px;
  overflow: hidden;
}

.fm-home-splash-images-background-img-back {
  position: absolute;
  right: 0;
  top: 0;
  width: 440px;
}

.fm-home-splash-images-background {
  position: relative;
}

.fm-home-splash-images-background-box {
  position: absolute;
  top: 80px;
  right: 200px;
  width: 400px;
  height: 600px;
  background: $primary;
}

.fm-home-splash-images-background-img-top {
  position: absolute;
  top: 190px;
  right: 300px;
  width: 510px;
}

.fm-home-splash-images-content {
  margin: 150px 0 200px 0;
  position: relative;
}

.fm-home-splash-images-title {
  font-size: 60px;
  letter-spacing: -1px;
  font-weight: 800;
  line-height: 90px;
}

.fm-home-splash-images-description {
  font-size: 18px;
  max-width: 225px;
  line-height: 30px;
  padding: 20px 10px;
  margin: 10px 0 20px 0;
}

.fm-home-splash-images-download-ios {
  font-size: 16px;
  background: $secondary;
  color: #fff;
  padding: 20px 30px;
  font-weight: 600;
  letter-spacing: 0.2px;
  margin: 15px 10px;
  display: inline-block;
  position: relative;
}

.fm-home-splash-images-download-android {
  font-size: 16px;
  background: $secondary;
  color: #fff;
  padding: 20px 30px;
  font-weight: 600;
  letter-spacing: 0.2px;
  margin: 15px 10px;
  display: inline-block;
  position: relative;
}

.fm-home-splash-images-download-text {
  position: relative;
}

.fm-home-splash-images-download-hover {
  background: $primary;
  position: absolute;
  top: 0;
  right: 100%;
  left: 0;
  bottom: 0;
  transition: cubic-bezier(0.4, 0, 1, 1) right 0.4s;
}

.fm-home-splash-images-download-ios:hover .fm-home-splash-images-download-hover, .fm-home-splash-images-download-android:hover .fm-home-splash-images-download-hover {
  right: 0%;
}

.fm-home-splash-images-content-box {
  width: 350px;
}

@media(max-width: $breakpoint-xl) {
  .fm-home-splash-images-content {
    margin: 150px 0 50px 0;
  }
  .fm-home-splash-images-background-img-top {
    right: 220px;
    width: 400px;
  }
  .fm-home-splash-images-background-img-back {
    width: 350px;
  }
  .fm-home-splash-images-background-box {
    right: 100px;
    width: 400px;
    height: 500px;
  }
}

@media(max-width: $breakpoint-lg) {
  .fm-home-splash-images-content {
    margin: 150px 0 50px 0;
  }
  .fm-home-splash-images-background-img-top {
    right: 50px;
    width: 400px;
    top: 50px;
  }
  .fm-home-splash-images-background-img-back {
    display: none;
  }
  .fm-home-splash-images-background-box {
    top: 0;
    right: 0;
  }
  .fm-home-splash-images-title {
    font-size: 60px;
    line-height: 75px;
  }
  .fm-home-splash-images-content {
    margin: 80px 0 50px 0;
  }
  .fm-home-splash-images-description {
    font-size: 16px;
    line-height: 28px;
    margin: 10px 0;
  }
  .fm-home-splash-images-download-ios, .fm-home-splash-images-download-android {
    margin: 10px;
    font-size: 15px;
    padding: 15px 20px;
  }
}

@media(max-width: $breakpoint-md) {
  .fm-home-splash-images {
    padding: 20px;
  }
  .fm-home-splash-images-content {
    margin: 50px 0;
  }
  .fm-home-splash-images-background-img-top {
    right: 50px;
    width: 250px;
    top: 100px;
  }
  .fm-home-splash-images-background-box {
    width: 250px;
    height: 300px;
    top: 50px;
  }
  .fm-home-splash-images-title {
    font-size: 55px;
    line-height: 70px;
  }
}

@media(max-width: $breakpoint-sm) {
  .fm-home-splash-images-title {
    font-size: 50px;
    line-height: 65px;
  }
  .fm-home-splash-images {
    padding: 10px;
  }
  .fm-home-splash-images-background-box {
    width: 200px;
    right: -150px;
  }
  .fm-home-splash-images-background-img-top {
    right: -100px;
  }
}
