.fm-join-plan-benefits {
  margin: 25px;
}

.fm-join-plan-benefits {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 50px 60px 50px;
}

.fm-join-plan-benefits-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  padding: 20px 0 40px 0;
}

.fm-join-plan-benefits-items {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  padding: 20px 0;
}

.fm-join-plan-benefits-item-description {
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.2px;
}

.fm-join-plan-benefits-item {
  flex: 1;
  padding-right: 100px;

  &:last-child {
    padding-right: 0;
  }
}

.fm-join-plan-benefits-item-image {
  width: 60px;
  height: 60px;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.fm-join-plan-benefits-item-title {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  margin: 15px 0;
  letter-spacing: 0.2px;
}

@media(max-width: $breakpoint-lg) {
  .fm-join-plan-benefits {
    margin: 20px;
  }
  .fm-join-plan-benefits-item {
    flex: 1 1;
    padding-right: 50px;
  }
}

@media(max-width: $breakpoint-md) {
  .fm-join-plan-benefits-items {
    flex-direction: column;
    align-items: unset;
  }
  .fm-join-plan-benefits {
    margin: 15px;
  }
  .fm-join-plan-benefits-item {
    padding-right: 0;
    margin-bottom: 40px;
  }
}
