.fm-home-splash-benefits {
  margin: 25px;
  position: relative;
  height: 500px;
}

.fm-home-splash-benefits-boxes {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.fm-home-splash-benefits-box-ptz, .fm-home-splash-benefits-box-student {
  flex: 1;
  background-position: 50%;
  background-size: cover;
  display: flex;
  height: 100%;
  align-items: flex-end;
}

.fm-home-splash-benefits-box-student {
  background-image: url('https://storage.googleapis.com/flamingo-static/images/facade/community-hero.jpg');
}

.fm-home-splash-benefits-box-ptz {
  background-image: url('https://storage.googleapis.com/flamingo-static/images/facade/merch.jpg');
  margin-left: 25px;
}

.fm-home-splash-benefits-container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 60px 50px;
  position: relative;
  display: flex;
  height: 100%;
}

.fm-home-splash-benefits-box-student-title, .fm-home-splash-benefits-box-ptz-title {
  font-weight: 600;
  font-size: 40px;
  margin-left: 80px;
  margin-left: calc(100% - 532px);
  width: 200px;
  margin-bottom: 80px;
  line-height: 50px;
  letter-spacing: 0.2px;
  color: #fff;
}

@media(max-width: $breakpoint-xl) {
  .fm-home-splash-benefits {
    height: 400px;
  }

  .fm-home-splash-benefits-box-student-title, .fm-home-splash-benefits-box-ptz-title {
    margin-left: 50px;
    margin-bottom: 50px;
    font-size: 35px;
    line-height: 45px;
  }
}

@media(max-width: $breakpoint-lg) {
  .fm-home-splash-benefits {
    margin: 20px;
  }
  .fm-home-splash-benefits-box-ptz {
    margin-left: 20px;
  }
}

@media(max-width: $breakpoint-md) {
  .fm-home-splash-benefits {
    height: 300px;
  }
  .fm-home-splash-benefits-box-student-title, .fm-home-splash-benefits-box-ptz-title {
    margin-left: 40px;
    margin-bottom: 30px;
  }
}

@media(max-width: $breakpoint-sm) {
  .fm-home-splash-benefits {
    height: 600px;
    margin: 15px;
  }
  .fm-home-splash-benefits-box-ptz {
    margin-left: 0;
  }
  .fm-home-splash-benefits-boxes {
    flex-direction: column;
  }
  .fm-home-splash-benefits-box-ptz, .fm-home-splash-benefits-box-student {
    width: 100%;
    margin-bottom: 15px;
  }
  .fm-home-splash-benefits-box-student-title, .fm-home-splash-benefits-box-ptz-title {
    margin-left: 40px;
    margin-bottom: 30px;
  }
}
