.fm-home-how-to-ride {
  margin: 25px;
}

.fm-home-how-to-ride-container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 60px 50px;
}

.fm-home-how-to-ride-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  padding: 20px 0 40px 0;
}

.fm-home-how-to-ride-items {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  padding: 20px 0;
}

.fm-home-how-to-ride-item-description {
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.2px;
}

.fm-home-how-to-ride-item {
  flex: 1;
  padding-right: 100px;

  &:last-child {
    padding-right: 0;
  }
}

.fm-home-how-to-ride-item-image {
  width: 100%;
  height: 210px;
  background-size: cover;
  background-position: 50%;
  margin-bottom: 30px;
}

.fm-home-how-to-ride-item-title {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  margin: 15px 0;
  letter-spacing: 0.2px;
}

@media(max-width: $breakpoint-lg) {
  .fm-home-how-to-ride {
    margin: 20px;
  }
  .fm-home-how-to-ride-item {
    flex: 1 1;
    padding-right: 50px;
  }
}

@media(max-width: $breakpoint-md) {
  .fm-home-how-to-ride-item-image {
    height: 120px;
  }
  .fm-home-how-to-ride-item-title {
    font-size: 18px;
    background-image: none;
  }
  .fm-home-how-to-ride-container {
    padding: 40px;
  }
}

@media(max-width: $breakpoint-sm) {
  .fm-home-how-to-ride-items {
    flex-direction: column;
    align-items: unset;
  }
  .fm-home-how-to-ride {
    margin: 15px;
  }
  .fm-home-how-to-ride-item {
    padding-right: 0;
    margin-bottom: 40px;
  }
  .fm-home-how-to-ride-item-image {
    height: 200px;
  }

  .fm-home-how-to-ride-item-title {
    background-image: url('https://storage.googleapis.com/flamingo-static/images/facade/arrow.png');
    border-bottom: 2px solid #fff;
  }
}
