.fm-input {
  border: 0;
  font-size: 16px;
  color: $text-color;
  border-bottom: 1px solid $text-color;
  padding: 10px 1px;
  transition: ease all 0.1s;
  display: block;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;

  &:focus {
    border-bottom: 1px solid $primary;
  }
}

.fm-input-container {
  margin: 30px 0;
}

.fm-input-label {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 2px;
}

.fm-input-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAACJSURBVEhL7ZC9CYBADEbP2iEEp7JxAPdwBZex02EcQ/0iBCzC/ZlTkDx4RYrLC+cMwzB+QQ1bRRtYwSAbPJQdoRe6bIbS41x32MEg9NULlJakStEBRqMRT44yFF+htDRkdpTJiT+OMilxtSgTE1ePMr54sSgjxYtHmXv8tShD8Qn212QYxnc4dwKskJKEHrOFUQAAAABJRU5ErkJggg==');
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 98% 50%;
  border-radius: 0;
  background-color: #fff;
}

.fm-input-textarea {
  max-width: 100%;
  resize: none;
}
