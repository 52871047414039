.fm-cities {
  padding: 50px 0;
  margin: 0 25px 100px 25px;
}

.fm-cities-container {
  max-width: 1200px;
  width: 100%;
  margin: 0px auto;
  padding: 0 50px;
}

.fm-cities-title {
  line-height: 40px;
  padding: 20px 0 50px 0;
  font-size: 70px;
  font-weight: 800;
  line-height: 90px;
}

.fm-cities-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px 0;
  gap: 100px;
}

.fm-cities-item {
  flex-basis: 25%;
  flex-basis: calc(33% - 66px);
}

.fm-cities-item-image {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: 50%;
}

.fm-cities-item-title {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  margin: 20px 0;
  letter-spacing: 0.2px;
  border-bottom: 1px solid $border-color;
  padding-bottom: 15px;
}

@media(max-width: $breakpoint-lg) {
  .fm-cities {
    margin: 0 20px 20px 20px;
  }
  .fm-cities-title {
    font-size: 60px;
    line-height: 75px;
    padding: 20px 0 40px 0;
  }
  .fm-cities-items {
    gap: 50px;
  }
  .fm-cities-item {
    flex-basis: 20%;
    flex-basis: calc(33% - 33px);
  }
}

@media(max-width: $breakpoint-md) {
  .fm-cities {
    padding: 0;
  }
  .fm-cities-title {
    font-size: 55px;
    line-height: 70px;
  }
  .fm-cities-container {
    padding: 0 40px;
  }
  .fm-cities-item-image {
    height: 120px;
  }
  .fm-cities-item-title {
    font-size: 18px;
    background-image: none;
  }
  .fm-cities-container {
    padding: 40px;
  }
}

@media(max-width: $breakpoint-sm) {
  .fm-cities-items {
    flex-direction: column;
    align-items: unset;
    gap: 40px;
  }
  .fm-cities {
    margin: 15px;
  }
  .fm-cities-item-image {
    height: 200px;
  }
}
