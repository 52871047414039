@import './components/contact-form/contact-form.scss';
@import './components/contact-quick/contact-quick.scss';

.fm-contact {
  padding: 50px 0;
  margin: 0 25px 100px 25px;
}

.fm-contact-container {
  max-width: 1150px;
  width: 100%;
  margin: 0px auto;
  padding: 0 25px;
}

.fm-contact-title {
  line-height: 40px;
  padding: 20px 0 50px 0;
  font-size: 70px;
  font-weight: 800;
  line-height: 90px;
}

.fm-contact-items {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

@media(max-width: $breakpoint-lg) {
  .fm-contact {
    margin: 0 20px 80px 20px;
  }
  .fm-contact-title {
    font-size: 60px;
    line-height: 75px;
  }
}

@media(max-width: $breakpoint-md) {
  .fm-contact-container {
    padding: 0 20px;
  }
  .fm-contact-items {
    flex-direction: column;
    align-items: stretch;
  }
  .fm-contact-title {
    font-size: 55px;
    line-height: 70px;
  }
}

@media(max-width: $breakpoint-sm) {
  .fm-contact {
    margin: 0 15px 50px 15px;
  }
  .fm-contact-title {
    font-size: 45px;
    line-height: 65px;
  }
}
