html, body {
  font-weight: 400;
  color: $text-color;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  font-family: 'Archivo', sans-serif;
  min-height: 100%;
  background-color: #fff;
  -webkit-text-size-adjust: none;
}

.fm-brand {
  font-family: 'Fugaz One', cursive;
  letter-spacing: -0.5px;
}

.fm-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fm-container {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.fm-text {
  font-size: 16px;
  margin: 5px 0;
  line-height: 25px;
  display: block;
}

.fm-bullet {
  font-size: 16px;
  margin: 5px 0 5px 20px;
  line-height: 25px;
}
