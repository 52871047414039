.fm-privacy-content {
  padding: 20px 0;
}

.fm-privacy-header, .fm-privacy-list-header {
  font-weight: 600;
  font-size: 1.5em;
  line-height: 1em;
  margin: 40px 0 15px 0;
}

.fm-privacy-text, .fm-privacy-list-text {
  margin-bottom: 12px;
  line-height: 22px;
}

.fm-privacy-link {
  color: $primary;
  text-decoration: none;
}

.fm-privacy-list {
  margin: 10px 0 20px 25px;
}

.fm-privacy-content {
  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
  }

  ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
  }

  ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
  }

  li ol > li {
    margin: 0;
  }

  li ol > li:before {
    content: counters(item, ".") " ";
  }

  ol > li > ol > li > ol {
    list-style-type: lower-alpha;
  }

  ol > li > ol > li > ol > li > ol {
    list-style-type: lower-roman;
  }

  ol > li > ol > li > ol > li {
    display: list-item;
    margin-left: 20px;
  }

  ol > li > ol > li > ol > li:before {
    content: "";
    display: none;
  }

  .fm-privacy-list-item-top:before {
    font-weight: 600;
    font-size: 1.5em;
    line-height: 1em;
  }
}
