.fm-home-splash-workplace {
  margin: 80px 25px 120px 25px;
}

.fm-home-splash-workplace-container {
  max-width: 1150px;
  width: 100%;
  margin: 0 auto;
  padding: 0 25px 20px 25px;
}

.fm-home-splash-workplace-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  padding: 20px 0 30px 0;
}

.fm-home-splash-workplace-description {
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.2px;
}

.fm-home-splash-button {
  line-height: 25px;
  font-size: 15px;
  padding: 15px 20px;
  background: #0c0f0a;
  color: #fff;
  font-weight: 600;
  letter-spacing: .2px;
  margin: 40px 0 15px 0;
  cursor: pointer;
  display: inline-block;
}

@media(max-width: $breakpoint-lg) {
  .fm-home-splash-workplace {
    margin: 50px 20px 120px 20px;
  }
}

@media(max-width: $breakpoint-md) {
  .fm-home-splash-workplace-container {
    padding: 0 20px 0 20px;
  }
}

@media(max-width: $breakpoint-sm) {
  .fm-home-splash-workplace {
    margin: 30px 15px 80px 15px;
  }
}
