.fm-contact-quick {
  background: #000;
  padding: 1px 50px;
}

.fm-contact-quick-title {
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  color: #fff;
  margin: 50px 0 20px 0;
}

.fm-contact-quick-link {
  display: block;
  color: #fff;
  letter-spacing: .2px;
  font-weight: 500;
  line-height: 22px;
  text-decoration: none;
  margin-bottom: 50px;
}

@media(max-width: $breakpoint-md) {
  .fm-contact-quick {
    padding: 1px 30px;
    margin-top: 50px;
  }
  .fm-contact-quick-title {
    margin: 30px 0 20px 0;
  }
  .fm-contact-quick-link {
    margin-bottom: 30px;
  }
}
