.fm-safety-videos {
  margin: 25px;
}

.fm-safety-videos-container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 20px 50px 60px 50px;
}

.fm-safety-videos-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  padding: 20px 0 40px 0;
}

.fm-safety-videos-items {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  padding: 20px 0;
}

.fm-safety-videos-item-description {
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.2px;
}

.fm-safety-videos-item {
  flex: 1;
  padding-right: 100px;

  &:last-child {
    padding-right: 0;
  }
}

.fm-safety-videos-item-image {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: 50%;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.fm-safety-videos-item-play {
  border-style: solid;
  box-sizing: border-box;
  border-width: 25px 0px 25px 50px;
  border-color: transparent transparent transparent #fff;
  opacity: 0.85;
  transition: ease all 0.2s;
  cursor: pointer;
}

.fm-safety-videos-item-image:hover .fm-safety-videos-item-play {
  border-width: 20px 0px 20px 40px;
  opacity: 1;
}

.fm-safety-videos-item-title {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  margin: 15px 0;
  letter-spacing: 0.2px;
}

@media(max-width: $breakpoint-lg) {
  .fm-safety-videos {
    margin: 20px;
  }
  .fm-safety-videos-item {
    flex: 1 1;
    padding-right: 50px;
  }
}

@media(max-width: $breakpoint-md) {
  .fm-safety-videos-item-image {
    height: 120px;
  }
  .fm-safety-videos-item-title {
    font-size: 18px;
    background-image: none;
  }
  .fm-safety-videos-container {
    padding: 40px;
  }
}

@media(max-width: $breakpoint-sm) {
  .fm-safety-videos-items {
    flex-direction: column;
    align-items: unset;
  }
  .fm-safety-videos {
    margin: 15px;
  }
  .fm-safety-videos-item {
    padding-right: 0;
    margin-bottom: 40px;
  }
  .fm-safety-videos-item-image {
    height: 200px;
  }
}

/* POP UP */

.fm-safety-video-player-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  -webkit-animation: fadein 0.8s;
  -moz-animation: fadein 0.8s;
  -ms-animation: fadein 0.8s;
  -o-animation: fadein 0.8s;
  animation: fadein 0.8s;
}

.fm-safety-video-player-box {
  width: 90%;
  max-width: 1000px;
  height: 80%;
  max-height: 500px;
}

.fm-safety-video-player-box iframe {
  width: 100%;
  height: 100%;
}

.fm-safety-video-player-box-close {
  color: #fff;
  opacity: 0.8;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  pointer-events: none;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
