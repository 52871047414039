.fm-city-download {
  margin-top: 80px;
  margin-bottom: 120px;
}

.fm-city-download-container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 50px;
}

.fm-city-download-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  padding: 10px 0 20px 0;
}

.fm-city-download-text {
  line-height: 25px;
  margin-bottom: 25px;
}

.fm-city-download-items {
  display: flex;
  flex-direction: row;
}

.fm-city-download-app {
  margin: 10px 10px 10px 0;
}

.fm-city-download-app-img {
  height: 45px;
}

@media(max-width: $breakpoint-md) {
  .fm-city-download-container {
    padding: 0 40px;
  }

  .fm-city-download-items {
    display: flex;
    flex-direction: column;
  }
}
