.fm-discounts-calculator {
  margin: 25px;
}

.fm-discounts-calculator-container {
  max-width: 1150px;
  width: 100%;
  margin: 0px auto;
  padding: 20px 25px;
}

.fm-discounts-calculator-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  padding: 20px 0 40px 0;
}

.fm-discounts-calculator-description {
  font-size: 15px;
  line-height: 25px;
  letter-spacing: .2px;
}

.fm-discounts-calculator-form {
  display: flex;
  margin: 30px 0;
  gap: 20px;
}

.fm-discounts-calculator-form-select {
  border: 1px solid #ddd;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  font-family: 'Archivo', sans-serif;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.2px;
  padding: 8px 13px;
  transition: ease all 0.1s;
  width: 100%;
  -webkit-appearance: none;

  &:focus {
    border: 1px solid #333;
  }
}

.fm-discounts-calculator-result {
  font-size: 15px;
  line-height: 25px;
  letter-spacing: .2px;
}

@media(max-width: $breakpoint-lg) {
  .fm-discounts-calculator-container {
    padding: 20px;
  }
}

@media(max-width: $breakpoint-md) {
  .fm-discounts-calculator {
    margin: 20px;
  }
}

@media(max-width: $breakpoint-sm) {
  .fm-discounts-calculator {
    margin: 15px;
  }
  .fm-discounts-calculator-form {
    flex-direction: column;
  }
}

/* ----- BLURB ----- */

.fm-discounts-blurb {
  margin: 25px;
}

.fm-discounts-blurb-container {
  max-width: 1150px;
  width: 100%;
  margin: 0 auto;
  padding: 0 25px 60px 25px;
}

.fm-discounts-blurb-text {
  padding: 20px 0 30px 0;
  font-size: 22px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: 0.2px;
}

@media(max-width: $breakpoint-lg) {
  .fm-discounts-blurb {
    margin: 20px;
  }
}

@media(max-width: $breakpoint-md) {
  .fm-discounts-blurb-container {
    padding: 0 20px 60px 20px;
  }
  .fm-discounts-blurb-text {
    font-size: 18px;
  }
}

@media(max-width: $breakpoint-sm) {
  .fm-discounts-blurb {
    margin: 15px;
  }
}
