.fm-home-splash-locations {
  background: black;
  margin: 25px;
}

.fm-home-splash-locations-container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 60px 50px;
}

.fm-home-splash-locations-title {
  font-size: 30px;
  font-weight: 600;
  color: #fff;
  line-height: 40px;
  padding: 20px 0 40px 0;
}

.fm-home-splash-locations-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px 0;
  gap: 100px;
}

.fm-home-splash-locations-item {
  flex-basis: 25%;
  flex-basis: calc(33% - 66px);
}

.fm-home-splash-locations-item-image {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: 50%;
}

.fm-home-splash-locations-item-title {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  line-height: 30px;
  margin: 20px 0;
  letter-spacing: 0.2px;
  border-bottom: 1px solid #fff;
  padding-bottom: 15px;
  background-image: url('https://storage.googleapis.com/flamingo-static/images/facade/arrow.png');
  background-repeat: no-repeat;
  background-size: 32px;
  background-position: 100% 0px;
}

@media(max-width: $breakpoint-lg) {
  .fm-home-splash-locations {
    margin: 20px;
  }
  .fm-home-splash-locations-items {
    gap: 50px;
  }
  .fm-home-splash-locations-item {
    flex-basis: 20%;
    flex-basis: calc(33% - 33px);
  }
}

@media(max-width: $breakpoint-md) {
  .fm-home-splash-locations-item-image {
    height: 120px;
  }
  .fm-home-splash-locations-item-title {
    font-size: 18px;
    background-image: none;
  }
  .fm-home-splash-locations-container {
    padding: 40px;
  }
}

@media(max-width: $breakpoint-sm) {
  .fm-home-splash-locations-items {
    flex-direction: column;
    align-items: unset;
    gap: 40px;
  }
  .fm-home-splash-locations {
    margin: 15px;
  }
  .fm-home-splash-locations-item-image {
    height: 200px;
  }
  .fm-home-splash-locations-item-title {
    background-image: url('https://storage.googleapis.com/flamingo-static/images/facade/arrow.png');
    border-bottom: 2px solid #fff;
  }
}
