.fm-footer {
  background: $secondary;
  margin-top: 80px;
  padding: 30px 0;
}

.fm-footer-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.fm-footer-logo {
  width: 40px;
  margin: 45px 0;
}

.fm-footer-wordmark {
  color: #fff;
  display: block;
}

.fm-footer-wordmark-img {
  height: 35px;
  padding: 10px 0 0 0;
}

.fm-footer-links {
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 20px 0 30px 0;
}

.fm-footer-link-column {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.fm-footer-link {
  display: block;
  margin: 3px 20px 3px 0;
  text-decoration: none;
  line-height: 20px;
  padding: 3px 0 1px 0;
  transition: ease all 0.2s;
}

.fm-footer-link:hover {
  padding: 3px 0 1px 0;
}

.fm-footer-link-text {
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  padding: 1px 2px 4px 0;
  border-bottom: 1px solid $secondary;
  transition: ease all 0.2s;
  letter-spacing: 0.5px;
}

.fm-footer-link:hover .fm-footer-link-text {
  border-bottom-color: #fff;
  padding: 1px 1px 2px 0;
}

.fm-footer-apps {
  align-items: flex-end;
}

.fm-footer-app {
  margin: 10px 0;
}

.fm-footer-app-img {
  width: 100%;
}

.fm-footer-copy {
  font-size: 11px;
  color: #fff;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 35px 0;
  text-transform: uppercase;
  opacity: 0.8;
}

/* LEGAL BULLSHIT */
.fm-footer-wordmark-tm {
  font-size: 5px;
  vertical-align: top;
  font-family: sans-serif;
  font-weight: bold;
  display: inline-block;
  line-height: 37px;
  padding-left: 2px;
}
/* REMOVE ONE DAY */

@media(max-width: 1200px) {
  .fm-footer {
    padding: 30px 20px;
  }
}

@media(max-width: $breakpoint-md) {
  .fm-footer-apps {
    display: none;
  }
}
