.fm-city-details {
  margin: 100px 25px 90px 25px;
}

.fm-city-details-boxes {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.fm-city-details-box {
  flex: 1;
  background: $secondary;
}

.fm-city-details-box-right {
  margin-left: 25px;
}

.fm-city-details-box-content {
  margin-left: 80px;
  margin-left: calc(100% - 537px);
  padding: 80px 0 75px 0;
  width: 300px;
}

.fm-city-details-title {
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 40px;
  line-height: 50px;
  letter-spacing: 0.5px;
  color: #fff;
}

.fm-city-details-line {
  margin: 5px 10px 15px 0px;
  color: #fff;
  letter-spacing: 0.2px;
  font-weight: 500;
  line-height: 22px;
}

@media(max-width: $breakpoint-xl) {
  .fm-city-details-box-content {
    margin-left: 25px;
    padding: 40px 25px 40px 0;
    width: auto;
  }
  .fm-city-details-title {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 30px;
  }
  .fm-city-details {
    margin: 80px 25px;
  }
}

@media(max-width: $breakpoint-md) {
  .fm-city-details {
    margin: 80px 15px;
  }
  .fm-city-details-box-right {
    margin-left: 15px;
  }
}

@media(max-width: $breakpoint-sm) {
  .fm-city-details-boxes {
    flex-direction: column;
  }
  .fm-city-details-box-right {
    margin-left: 0;
    margin-top: 15px;
  }
  .fm-city-details-box {
    width: 100%;
  }
}
