.fm-contact-form {
  flex: 1;
  margin-right: 100px;
}

.fm-contact-form-intro {
  font-size: 16px;
  line-height: 25px;
  letter-spacing: .2px;
}

.fm-contact-form-options {
  margin: 40px 0;
  display: flex;
  gap: 20px;
}

.fm-contact-form-option {
  flex: 1;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  cursor: pointer;

  &:hover {
    border: 1px solid #333;
  }
}

.fm-contact-form-option-selected {
  border: 1px solid #333;
}

.fm-contact-form-option-img {
  height: 30px;
  margin: 10px 0;
}

.fm-contact-form-option-label {
  text-align: center;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: .2px;
  max-width: 92px;
  margin-top: 10px;
}

.fm-contact-form-warning {
  font-size: 15px;
  color: #fff;
  background: #e84118;
  line-height: 25px;
  padding: 8px 12px;
  font-weight: 600;
  margin-bottom: 40px;
}



.fm-contact-form-input-container {
  margin-bottom: 20px;
}

.fm-contact-form-input-label {
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.2px;
  margin: 5px 0;
}

.fm-contact-form-input {
  border: 1px solid #ddd;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  font-family: 'Archivo', sans-serif;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.2px;
  padding: 8px 13px;
  transition: ease all 0.1s;
  width: 100%;

  &:focus {
    border: 1px solid #333;
  }
}

.fm-contact-form-textarea {
  border: 1px solid #ddd;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  font-family: 'Archivo', sans-serif;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.2px;
  padding: 8px 13px;
  transition: ease all 0.1s;
  width: 100%;
  max-width: 100%;
  resize: vertical;

  &:focus {
    border: 1px solid #333;
  }
}

.fm-contact-form-button {
  line-height: 25px;
  font-size: 15px;
  padding: 15px 20px;
  background: #0c0f0a;
  color: #fff;
  font-weight: 600;
  letter-spacing: .2px;
  margin: 15px 0;
  cursor: pointer;
}

@media(max-width: $breakpoint-xl) {
  .fm-contact-form {
    margin-right: 80px;
  }
}

@media(max-width: $breakpoint-lg) {
  .fm-contact-form-options {
    flex-wrap: wrap;
  }
  .fm-contact-form-option {
    flex: 45%;
  }
}

@media(max-width: $breakpoint-md) {
  .fm-contact-form {
    margin-right: 0;
  }
}

@media(max-width: $breakpoint-sm) {
  .fm-contact-form-option {
    padding: 15px 0;
  }
  .fm-contact-form-option-img {
    margin: 5px 0;
  }
  .fm-contact-form-option-label {
    line-height: 20px;
  }
}
