.fm-workplace-session-blurb {
  margin: 25px;
}

.fm-workplace-session-blurb-container {
  max-width: 1150px;
  width: 100%;
  margin: 0 auto;
  padding: 0 25px 20px 25px;
}

.fm-workplace-session-blurb-text {
  padding: 20px 0 30px 0;
  font-size: 22px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: 0.2px;
}

@media(max-width: $breakpoint-lg) {
  .fm-workplace-session-blurb {
    margin: 20px;
  }
}

@media(max-width: $breakpoint-md) {
  .fm-workplace-session-blurb-container {
    padding: 0 20px 0 20px;
  }
  .fm-workplace-session-blurb-text {
    font-size: 18px;
    padding: 20px 0 0 0;
  }
}

@media(max-width: $breakpoint-sm) {
  .fm-workplace-session-blurb {
    margin: 15px;
  }
}
