.fm-quiz-details {
  margin: 25px;
}

.fm-quiz-details {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 20px 50px 60px 50px;
}

.fm-quiz-details-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  padding: 20px 0 40px 0;
}

.fm-quiz-details-description {
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.2px;
}

.fm-quiz-details-terms {
  color: $light-text-color;
  font-size: 12px;
  line-height: 20px;
  margin-top: 50px;
}
