.fm-join-plan-form {
  margin: 25px;
}

.fm-join-plan-form {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 20px 50px 60px 50px;
}

.fm-join-plan-form-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  padding: 20px 0 40px 0;
}

.fm-join-plan-form-description {
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.2px;
}

.fm-join-plan-form-inputs {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 25px 0;
}

.fm-join-plan-form-input-container {
  flex: 1;
}

.fm-join-plan-form-input-container + .fm-join-plan-form-input-container {
  margin: 0 0 0 20px;
}

.fm-join-plan-form-input-label {
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.2px;
  margin: 5px 0;
}

.fm-join-plan-form-input {
  border: 1px solid #ddd;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  font-family: 'Archivo', sans-serif;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.2px;
  padding: 8px 13px;
  transition: ease all 0.1s;
  width: 100%;

  &:focus {
    border: 1px solid #333;
  }
}

.fm-join-plan-form-button {
  line-height: 25px;
  font-size: 15px;
  padding: 15px 20px;
  background: #0c0f0a;
  color: #fff;
  font-weight: 600;
  letter-spacing: .2px;
  margin: 15px 0;
  cursor: pointer;
}

@media(max-width: $breakpoint-sm) {
  .fm-join-plan-form-input-container + .fm-join-plan-form-input-container {
    margin: 20px 0 0 0;
  }
  .fm-join-plan-form-input-container {
    width: 100%;
  }
  .fm-join-plan-form-inputs {
    display: flex;
    flex-direction: column;
  }
}
