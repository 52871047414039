.fm-about-sustainability {
  margin: 100px 25px 90px 25px;
}

.fm-about-sustainability-boxes {
  display: flex;
  align-items: stretch;
  flex-direction: row;
}

.fm-about-sustainability-box {
  flex: 1;
  background: $secondary;
}

.fm-about-sustainability-box-right {
  margin-left: 25px;
}

.fm-about-sustainability-box-left {
  background-image: url('https://storage.googleapis.com/flamingo-static/images/facade/discounts.jpg');
  background-size: cover;
  background-position: 50%;
}

.fm-about-sustainability-box-content {
  margin-left: 80px;
  margin-left: calc(100% - 537px);
  padding: 80px 0 75px 0;
  width: 300px;
}

.fm-about-sustainability-title {
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 40px;
  line-height: 50px;
  letter-spacing: 0.5px;
  color: #fff;
}

.fm-about-sustainability-line {
  margin: 5px 10px 15px 0px;
  color: #fff;
  letter-spacing: 0.2px;
  font-weight: 500;
  line-height: 22px;
}

@media(max-width: $breakpoint-xl) {
  .fm-about-sustainability-box-content {
    margin-left: 25px;
    padding: 40px 25px 40px 0;
    width: auto;
  }
  .fm-about-sustainability-title {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 30px;
  }
  .fm-about-sustainability {
    margin: 80px 25px;
  }
}

@media(max-width: $breakpoint-md) {
  .fm-about-sustainability {
    margin: 80px 15px;
  }
  .fm-about-sustainability-box-right {
    margin-left: 15px;
  }
}

@media(max-width: $breakpoint-sm) {
  .fm-about-sustainability {
    margin: 80px 15px 60px 15px;
  }
  .fm-about-sustainability-boxes {
    display: block;
  }
  .fm-about-sustainability-box-right {
    margin-left: 0;
    margin-top: 15px;
  }
  .fm-about-sustainability-box-left {
    height: 300px;
  }
  .fm-about-sustainability-box {
    width: 100%;
  }
}
