.fm-how-to-splash-image {
  position: relative;
  background: #fff;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px 50px;
  overflow: hidden;
}

.fm-how-to-splash-image-background {
  position: relative;
}

.fm-how-to-splash-image-background-box {
  position: absolute;
  top: 0;
  right: 0;
  width: 600px;
  height: 400px;
  background: $primary;
}

.fm-how-to-splash-image-background-img {
  position: absolute;
  top: 50px;
  right: 50px;
  width: 600px;
  height: 400px;
  background-position: 50%;
  background-size: cover;
}

.fm-how-to-splash-image-content {
  margin: 175px 0 80px 0;
  position: relative;
}

.fm-how-to-splash-image-title {
  font-size: 70px;
  font-weight: 800;
  line-height: 90px;
}

.fm-how-to-splash-image-description {
  font-size: 18px;
  max-width: 225px;
  line-height: 30px;
  padding: 20px 10px;
  margin: 10px 0 20px 0;
}

.fm-how-to-splash-image-content-box {
  width: 380px;
}

@media(max-width: $breakpoint-xl) {
  .fm-how-to-splash-image-content {
    margin: 160px 0 80px 0;
  }
  .fm-how-to-splash-image-background-img {
    width: 450px;
  }
  .fm-how-to-splash-image-background-box {
    width: 450px;
  }
}

@media(max-width: $breakpoint-lg) {
  .fm-how-to-splash-image-background-img {
    width: 350px;
    top: 30px;
    right: 30px;
  }
  .fm-how-to-splash-image-background-box {
    width: 350px;
  }
  .fm-how-to-splash-image-content {
    margin: 140px 0 80px 0;
  }
  .fm-how-to-splash-image-title {
    font-size: 60px;
    line-height: 75px;
  }
  .fm-how-to-splash-image-description {
    font-size: 16px;
    line-height: 28px;
    margin: 10px 0;
  }
}

@media(max-width: $breakpoint-md) {
  .fm-how-to-splash-image {
    padding: 20px;
  }
  .fm-how-to-splash-image-content {
    margin: 100px 0 80px 0;
  }
  .fm-how-to-splash-image-background-img {
    width: 225px;
    top: 25px;
    right: 25px;
    height: 300px
  }
  .fm-how-to-splash-image-background-box {
    width: 225px;
    height: 300px
  }
  .fm-how-to-splash-image-title {
    font-size: 55px;
    line-height: 70px;
  }
  .fm-how-to-splash-image-description {
    margin: 0;
    padding: 10px 5px;
    max-width: 175px;
  }
}

@media(max-width: $breakpoint-sm) {
  .fm-how-to-splash-image {
    padding: 10px;
  }
  .fm-how-to-splash-image-content {
    margin: 60px 0 60px 0;
  }
  .fm-how-to-splash-image-title {
    font-size: 45px;
    line-height: 65px;
  }
  .fm-how-to-splash-image-background-box {
    width: 80px;
    height: 200px;
    right: -25px;
  }
  .fm-how-to-splash-image-background-img {
    width: 225px;
    top: 40px;
    right: -110px;
    height: 200px;
  }
  .fm-how-to-splash-image-content-box {
    width: auto;
    max-width: 450px;
    margin-right: 100px;
  }
}
