.fm-news-article {
  max-width: 850px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 25px 20px 25px;
}

.fm-news-article-pre {
  padding: 0 0 0 0;
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  color: $primary;
}

.fm-news-article-title {
  margin: 20px 0 30px 0;
  font-size: 50px;
  font-weight: 800;
  line-height: 70px;
}

.fm-news-article-date {
  margin: 20px 0 30px 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.2px;
}

.fm-news-article-img {
  width: 100%;
  max-width: 100%;
}

.fm-news-article-text {
  margin: 25px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.2px;
}

.fm-news-article-list-item {
  margin: 15px 0 15px 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.2px;
}

@media(max-width: $breakpoint-lg) {
  .fm-news-article-title {
    font-size: 50px;
    line-height: 70px;
  }
}

@media(max-width: $breakpoint-md) {
  .fm-news-article {
    padding: 0 20px 0 20px;
  }
  .fm-news-article-title {
    font-size: 45px;
    line-height: 65px;
  }
}

@media(max-width: $breakpoint-sm) {
  .fm-news-article-title {
    font-size: 40px;
    line-height: 60px;
  }
}
